import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import FAQsPage from './containers/FAQsPage/FAQsPage'
// const FAQsPage = loadable(() => import(/* webpackChunkName: "FAQsPage" */ './containers/FAQsPage/FAQsPage'));

// import HostAccountDeatils from './containers/HostAccountDetails/HostAccountDeatils';
import InsuranceDetailPage from './containers/InsuranceDetailPage/InsuranceDetailPage';

const pageDataLoadingAPI = getPageDataLoadingAPI();
const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const VerificationPage = loadable(() => import(/* webpackChunkName: "VerificationPage" */ './containers/VerificationPage/VerificationPage'));
const VerificationAccountSettings = loadable(() => import(/* webpackChunkName: "VerificationPage" */ './containers/VerificationPage/VerificationAccountSettings'));
const PanelPage = loadable(() => import(/* webpackChunkName: "PanelPage" */ './containers/PanelPage/PanelPage'));

// const ThankYouPage = loadable(() => import(/* webpackChunkName: "ThankYouPage" */ './containers/ThankYouPage/ThankYouPage'));
const HostDetailsPage = loadable(() => import(/* webpackChunkName: "HostDetailsPage" */ './containers/HostDetailsPage/HostDetailsPage'));
const ContactPage = loadable(() => import(/* webpackChunkName: "ContactPage" */ './containers/ContactPage/ContactPage'));
const DashBoardPage = loadable(() => import(/* webpackChunkName: "DashBoard" */ './containers/DashBoardPage/DashBoardPage'));

const TrustSafetyPage = loadable(() => import(/* webpackChunkName: "TrustSafetyPage" */ './containers/TrustSafetyPage/TrustSafetyPage'));
const InstantBookingPage = loadable(() => import(/* webpackChunkName: "TrustSafetyPage" */ './containers/InstantBookingPage/InstantBookingPage'));
const CareersPage = loadable(() => import(/* webpackChunkName: "TrustSafetyPage" */ './containers/CareersPage/CareersPage'));
const HowItWorksPage = loadable(() => import(/* webpackChunkName: "TrustSafetyPage" */ './containers/HowItWorksPage/HowItWorksPage'));
const BecomeHostPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/BecomeHostPage/BecomeHostPage'));
const HowToRentPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/HowToRentPage/HowToRentPage'));
const FavListingsPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/FavListingsPage/FavListingsPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));


const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
// const AdobePage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/AdobePage/AdobePage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const DriverApprovalPage = loadable(() => import(/* webpackChunkName: "DriverApprovalPage" */ './containers/DriverApprovalPage/DriverApprovalPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const RentalPage = loadable(() => import(/* webpackChunkName: "RentalPage" */ './containers/RentalPage/RentalPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const BookingDetailsPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/BookingDetailsPage/BookingDetailsPage'));

const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
// const BlogPage = loadable(() => import(/* webpackChunkName: "BlogPage" */ './containers/BlogPage/BlogPage'));
const InsurancePage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/InsurancePage/InsurancePage'));
// const OurBlogPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/OurBlogPage/OurBlogPage'));
const WorkWithUsPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/WorkWithUsPage/WorkWithUsPage'));
const ReservationPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/ReservationPage/ReservationPage'));
const TestimoniosPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/TestimoniosPage/TestimoniosPage'));
const CvPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/CvPage/CvPage'));
const PayoutDetailsPage = loadable(() => import(/* webpackChunkName: "InsurancePage" */ './containers/PayoutDetailsPage/PayoutDetailsPage'));
const ReferralPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/ReferralPage/ReferralPage'));
const CohostPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/CohostPage/CohostPage'));
const HomePage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/HomePage/HomePage'));
const HostAccountDeatils = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/HostAccountDetails/HostAccountDeatils'));
const DeleteAccountPage = loadable(() => import(/* webpackChunkName: "DeleteAccountPage" */ './containers/DeleteAccountPage/DeleteAccountPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'DeleteAccountPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
    },
    // {
    //   path: '/adobeSignIn',
    //   name: 'AdobePage',
    //   component: AdobePage,
    // },
    {
      path: '/landingPage',
      name: 'LandingPage',
      component: LandingPage,
    },

    {
      path: '/HowToRent',
      name: 'HowToRentPage',
      component: HowToRentPage,
    },
    {
      path: '/becomeHost',
      name: 'BecomeHostPage',
      component: BecomeHostPage,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },

    // {
    //   path: '/adobeSign',
    //   name: 'ThankYouPage',
    //   component: ThankYouPage,
    // },
    // {
    //   path: '/blog',
    //   name: 'BlogPage',
    //   component: BlogPage,
    // },
    // {
    //   path: '/ourBlog',
    //   name: 'OurBlogPage',
    //   component: OurBlogPage,
    // },
    {
      path: '/FAQs',
      name: 'FAQsPage',
      component: FAQsPage,
    },
    {
      path: '/Insurance',
      name: 'InsurancePage',
      component: InsurancePage,
    },
    {
      path: '/InsuranceDetailPage',
      name: 'InsuranceDetailPage',
      component: InsuranceDetailPage,
    },
    {
      path: '/host/:step',
      name: 'HostDetailsPage',
      auth:true,
      component: HostDetailsPage,
    },
    {
      path: '/testimonios',
      name: 'TestimoniosPage',
      component: TestimoniosPage,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: ContactPage,
    },
    {
      path: '/work-with-us',
      name: 'WorkWithUsPage',
      component: WorkWithUsPage,
    },
    {
      path: '/reservation',
      name: 'ReservationPage',
      component: ReservationPage,
    },
    {
      path: '/cv',
      name: 'CvPage',
      component: CvPage,
    },
    {
      path: '/trust-safety',
      name: 'TrustSafetyPage',
      component: TrustSafetyPage,
    },

    {
      path: '/instant-booking',
      name: 'InstantBookingPage',
      component: InstantBookingPage,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorksPage',
      component: HowItWorksPage,
    },
    {
      path: '/careers',
      name: 'CareersPage',
      component: CareersPage,
    },
    {
      path: '/favListings',
      name: 'FavListingsPage',
      component: FavListingsPage,
      loadData: pageDataLoadingAPI.FavListingsPage.loadData,

    },

    {
      path: '/referral',
      name: 'ReferralPage',
      component: ReferralPage,
    },
    {
      path: '/hostAccountDetails',
      name: 'HostAccountDeatils',
      component: HostAccountDeatils,
      loadData: pageDataLoadingAPI.HostAccountDeatils.loadData,
    },
    {
      path: '/cohost',
      name: 'CohostPage',
      component: CohostPage,
    },
    {
      path: '/bookingDetails',
      name: 'BookingDetailsPage',
      loadData: pageDataLoadingAPI.BookingDetailsPage.loadData,
      component: BookingDetailsPage,
    },
    {
      path: '/payoutDetailsPage',
      name: 'PayoutDetailsPage',
      component: PayoutDetailsPage,
      loadData: pageDataLoadingAPI.PayoutDetailsPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },

    {
      path: '/panel',
      name: 'PanelPage',
      component: PanelPage,
      loadData: pageDataLoadingAPI.PanelPage.loadData,
    },

    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => < NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },

    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/success/:paymentId',
      name: 'OrderSuccessPage',
      component: props => <OrderSuccessPage {...props} />,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },

    {
      path: '/dashboard',
      name: 'DashBoardPage',
      auth: true,
      component: DashBoardPage,
      loadData: pageDataLoadingAPI.DashBoardPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path:'/account/verification',
      name:'VerificationAccountSettings',
      auth: true,
      component:VerificationAccountSettings,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/delete-profile',
      name: 'DeleteAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: DeleteAccountPage,
    },
    {
      path: '/driver/:step',
      name: 'DriverApprovalPage',
      auth: true,
      authPage: 'LoginPage',
      component: DriverApprovalPage,
      loadData: pageDataLoadingAPI.DriverApprovalPage.loadData,
    },
    {
      path: '/host/:step',
      name: 'HostDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: HostDetailsPage,
      loadData: pageDataLoadingAPI.HostDetailsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/rental-policy',
      name: 'RentalPage',
      component: RentalPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    {
      path: '/verification',
      name: 'VerificationBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="VerificationPage" params={{ tab: 'host' }} />,
    },
    {
      path: '/verification/:tab',
      name: 'VerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: VerificationPage,
      loadData: pageDataLoadingAPI.VerificationPage.loadData,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
