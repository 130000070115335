import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, NamedLink, Button, IconCard } from '../../components';
import * as validators from '../../util/validators';
import AppleLogin from 'react-apple-login';
import css from './LoginForm.module.css';
import facebook from './facebook.jpg';
import google from './google.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import suzukii from './Grupo -8.png';
import { pushToPath } from '../../util/urlHelpers';
import car from './005-car.png';
import pickup from './001-pick-up-truck.png';
import family from './002-family-car.png';
import limousine from './006-limousine.png';
import electric from './007-electric-car.png';

const LoginFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          authWithGoogle,
          authWithFacebook,
          handleAppleLogin,
          appleLoginError,
          loginOrSignupError,
          // handleSubmitConfirm,
        } = fieldRenderProps;

        // email
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
            <FormattedMessage id="LoginForm.forgotPassword" />
          </NamedLink>
        );

        const renderAppleButton = (props) => (
          <button type='button' className={css.appleLogIn} {...props}>
            <span>
              <span className={css.appleLogo}>
                <IconCard brand="applelogo" />
              </span>
              <span className={css.appleButton}>
                Continue with Apple
              </span>
            </span>
            <span>
              <ArrowForwardIcon sx={{ color: '#ffffff' }} />
            </span>
          </button>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <>
              <div className={css.rowWrapper}>
                <div className={css.inputWrapper}>
                  <div className={css.mobile}>
                    <p className={css.mobileText}>Iniciar sesión</p>
                  </div>
                  <div>
                </div>
                  <div className={css.border}>
                    <div className={css.row} onClick={authWithGoogle}>
                      <img src={google} className={css.google} alt='google' />
                      <p>Ingresar con Google</p>
                      <ArrowForwardIcon sx={{ color: '#ACACAC' }} />
                    </div>

                  </div>
                  <div className={css.appleLogin}>
                    <AppleLogin
                      clientId={"com.rentalo.rent.service"}
                      redirectURI={process.env.REACT_APP_CANONICAL_ROOT_URL}
                      responseType={"id_token"}
                      responseMode={"form_post"}
                      scope={"name email"}
                      usePopup={true}
                      callback={handleAppleLogin}
                      render={renderAppleButton}
                    />
                  </div>

                  <div className={css.border} onClick={authWithFacebook}>
                    <div className={css.row}>
                      <img src={facebook} className={css.google} alt='facebook' />
                      <p>Ingresar con Facebook</p>
                      <ArrowForwardIcon sx={{ color: '#ACACAC' }} />
                    </div>
                  </div>
                  <div className={css.box}>
                    <p className={css.text2}>Ingrese con su correo electrónico y contraseña</p>
                    <div>
                      <FieldTextInput
                        className={css.password}
                        type="email"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        autoComplete="email"
                        placeholder="Correo electrónico"
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                      <FieldTextInput
                        className={css.password}
                        type="password"
                        id={formId ? `${formId}.password` : 'password'}
                        name="password"
                        autoComplete="current-password"
                        placeholder="Contraseña"
                        validate={passwordRequired}
                      />
                    </div>

                    <p style={{color:"red"}}>{appleLoginError}</p>
                    <p style={{color:"red"}}>{loginOrSignupError}</p>

                    <Button
                      className={css.button}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                    >
                      Iniciar sesión
                    </Button>

                    <p className={css.text} onClick={() => pushToPath('/signup')}>
                      Crear cuenta{' '}
                    </p>
                    <p className={css.bottomWrapperText}>
                      <span className={css.recoveryLinkInfo}>
                        <FormattedMessage
                          id="LoginForm.forgotPasswordInfo"
                          values={{ passwordRecoveryLink }}
                        />
                      </span>
                    </p>
                  </div>
                  <div className={css.row}>
                    <div className={css.carBox}>
                      <img src={car} className={css.car} alt='coche' />
                    </div>
                    <div className={css.carBox}>
                      <img src={limousine} className={css.car} alt='limusina' />
                    </div>
                    <div className={css.carBox}>
                      <img src={family} className={css.car} alt='familia' />
                    </div>
                    <div className={css.carBox}>
                      <img src={electric} className={css.car} alt='eléctrico' />
                    </div>
                    <div className={css.carBox}>
                      <img src={pickup} className={css.car} alt='camioneta' />
                    </div>
                  </div>
                </div>
                <div className={css.formDetailsRight}>
                  <div className={css.box1}>
                    <h1 className={css.title1}>Renta tu vehículo favorito</h1>
                    <p className={css.pTag}>
                      <strong>Réntalo®</strong>
                      <span className={css.text1}>
                        {' '}
                        Es la plataforma de alquiler de autos más novedosa, segura y rentable. En Réntalo si eres dueño de un vehículo o una flota completa puedes rentarlo a ciudadanos y turistas de todo el mundo. El viaje estará asegurado por nuestra póliza de seguro para tranquilidad del propietario y el conductor.
                      </span>
                    </p>
                    <img src={suzukii} className={css.pic} alt='suzukii' />
                  </div>
                </div>
              </div>
            </>
          </Form>
        );
      }}
    />
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
