import moment from 'moment';
import { TRANSITION_ACCEPT, TRANSITION_CUSTOMER_FULL_REFUND } from './transaction';
import { SERVICE_FEE } from './types';
import { types as sdkTypes } from '../util/sdkLoader';
const { REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY } = process.env;
const CONVERSION_VALUE = 60.30;
const { Money } = sdkTypes;

export const getStripeCustomer = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.privateData?.stripeCustomer
  );
};

export const getPaymentMethod = currentUser => {
  return (
    !!currentUser &&
    !!currentUser?.id &&
    currentUser?.attributes?.profile?.privateData?.stripePaymentMethod
  );
};

export const getCardToken = currentUser => {
  return currentUser && currentUser.id && currentUser?.attributes?.profile?.privateData?.cardToken;
};

export const getMessageBody = msg => {
  return msg && msg.attributes && msg.attributes.content;
};

export const getAuthorName = msg => {
  return msg?.sender?.attributes?.profile?.displayName;
};

export const getHostData = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.hostProfile
  );
};

export const getDriverLastStep = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.lastDriverStep
  );
};

export const getHostLastStep = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.lastHostStep
  );
};

export const isHostVerified = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    !!currentUser?.attributes?.profile?.protectedData?.hostIdentification?.isComplete
  );
};

export const isDriverVerified = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.lastDriverStep);
};

export const getDriverProfile = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.isDriverVerfied?.key
  );
};

export const getHostIdentification = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.isHostVerfied?.key
  );
};

export const getDriverPhoneNumber = currentUser => {
  // return (
  //   currentUser &&
  //   currentUser.id &&
  //   currentUser?.attributes?.profile?.protectedData?.driverPhoneNumber
  // );

  const {
    driverPhoneNumber,
    phoneNumber,
  } = currentUser?.attributes?.profile?.protectedData ?? {};

  return phoneNumber ?? driverPhoneNumber;
};
export const isPhoneNumberVerified = currentUser => {
  // return (
  //   currentUser &&
  //   currentUser.id &&
  //   currentUser?.attributes?.profile?.protectedData?.driverVerifiedPhoneNumber
  // );

  const {
    driverVerifiedPhoneNumber,
    verifiedPhoneNumber,
  } = currentUser?.attributes?.profile?.protectedData ?? {};

  return verifiedPhoneNumber ?? driverVerifiedPhoneNumber;
};
export const isHostPhoneNumberVerified = currentUser => {
  // return (
  //   currentUser &&
  //   currentUser.id &&
  //   currentUser?.attributes?.profile?.protectedData?.hostVerifiedPhoneNumber
  // );

  const {
    hostVerifiedPhoneNumber,
    verifiedPhoneNumber,
  } = currentUser?.attributes?.profile?.protectedData ?? {};

  return verifiedPhoneNumber ?? hostVerifiedPhoneNumber;
};

export const getHostPhoneNumber = currentUser => {
  const {
    hostPhoneNumber,
    phoneNumber,
  } = currentUser?.attributes?.profile?.protectedData ?? {};

  return phoneNumber ?? hostPhoneNumber;
};

export const getDriverDocumentNumber = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.documentDetails?.documentNumber
  );
};

export const getDriverDateOfBirth = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.holderDetails?.dob
  );
};

export const getDriverFullName = currentUser => {
  if (
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.protectedData &&
    currentUser.attributes.profile.protectedData.driverMetaData &&
    currentUser.attributes.profile.protectedData.driverMetaData.holderDetails
  ) {
    const { firstName, lastName } = currentUser.attributes.profile.protectedData.driverMetaData.holderDetails;
    if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
      return `${firstName[0]} ${lastName[0]}`;
    }
  }
  return null; // Return null if any of the required data is missing
};
export const getHostFullName = currentUser => {
  if (
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.protectedData &&
    currentUser.attributes.profile.protectedData.hostMetaData &&
    currentUser.attributes.profile.protectedData.hostMetaData.holderDetails
  ) {
    const { firstName, lastName } = currentUser.attributes.profile.protectedData.hostMetaData
    .holderDetails;
    if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
      return `${firstName[0]} ${lastName[0]}`;
    }
  }
  return null; // Return null if any of the required data is missing
};

export const getDriverStatus = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.driverIdentityStatus
  );
};
export const getDriverComplyCubeId = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.driverClientId
  );
};

export const getHostStatus = currentUser => currentUser?.attributes?.profile?.protectedData?.hostMetaData?.hostIdentityStatus
export const getFlightDetails = currentTransaction =>  currentTransaction?.attributes?.metadata?.flightDetails



export const getOdooUserID = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.privateData &&
    currentUser?.attributes?.profile?.privateData?.odoo_user_id
  );
};





export const getOdooUserCountryNumber = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.documents &&
    currentUser?.attributes?.profile?.protectedData?.driverMetaData?.documents[0]?.country
  );
};

export const getUserEmail = currentUser => {
  return currentUser && currentUser.id && currentUser?.attributes?.email;
};
export const getLicenseplate = listing => {
  return listing && listing.id && listing?.attributes?.publicData?.licenseplate;
};

export const sameMonthCancelledBooking = tx => {
  const transitionCancelledByCustomer =
    !!tx?.id &&
    tx?.attributes?.transitions?.find(item => item?.transition === TRANSITION_CUSTOMER_FULL_REFUND)?.createdAt;
  const currentMonth = moment().month();
  const isSameMonthCancelled =
    !!transitionCancelledByCustomer &&
    moment(transitionCancelledByCustomer).month() === currentMonth;
  return isSameMonthCancelled;
};

export const getBookingStartDate = tx => !!tx?.id && tx?.booking?.attributes?.start;
export const getBookingEndDate = tx => !!tx?.id && tx?.booking?.attributes?.end;


export const getBookingAcceptDate = tx => !!tx?.id && tx?.attributes?.transitions?.find(
  item => item?.transition === TRANSITION_ACCEPT)?.createdAt;

export const getServiceFee = tx => !!tx?.id && tx?.attributes?.lineItems?.find(item => item?.code === SERVICE_FEE)?.lineTotal
  ?.amount;

export const getUserDisplayName = user => !!user?.id && user?.attributes?.profile?.displayName;

export const setReferralCode = str => {
  const lastSix = str?.substr(str.length - 6);
  const reversedCharacters = lastSix
    .split('')
    .reverse()
    .join('');
  const finalCode = reversedCharacters.toUpperCase();
  return finalCode;
};

export const getReferralCode = user => !!user?.id && user?.attributes?.profile?.protectedData?.referralCode;

export const getBookingDataFromTransaction = tx => !!tx?.id && tx?.attributes?.protectedData?.extendedBookingData;


export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getVerifiedSteps = (steps, user, isDriver) => {

  const currentEmail = user.attributes.email || '';
  const emailVerified = user.attributes.emailVerified;
  const protectedData = user?.attributes.profile.protectedData || {};
  const currentPhoneNumber = protectedData.phoneNumber || '';
  const verifiedPhoneNumber = protectedData.verifiedPhoneNumber;
  const hostInsuranceDetails = protectedData?.hostInsuranceDetails;
  const accountType = protectedData?.hostIdentification?.accountType;
  const documentVerificationStatus = protectedData?.verification_outcome;
  const profileImage = user?.profileImage?.id || user?.relationships?.profileImage?.data?.id;
  const hostStatus = getHostStatus(user);
  const driverStatus = getDriverStatus(user);
  const identityStatus = isDriver ? driverStatus : hostStatus;
  const cardData = getPaymentMethod(user);
  const privateData = user?.attributes.profile.privateData || {};
  const odooBankAccount = privateData.odoo_bank_account_id;

  const verifiedSteps = steps.map(step => {
    if (step.email && currentEmail.length > 0 && emailVerified) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.phone && currentPhoneNumber.length > 0 && verifiedPhoneNumber) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.profile && profileImage) {
      return {
        ...step,
        verified: true,
      };
    }
    if (
      step.identity && identityStatus === "verified" &&
      (!documentVerificationStatus || documentVerificationStatus === "clear")
    ) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.payment && cardData) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.company && accountType) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.bank && odooBankAccount) {
      return {
        ...step,
        verified: true,
      };
    }
    if (step.insurance && hostInsuranceDetails) {
      return {
        ...step,
        verified: true,
      };
    } else {
      return step;
    }
  });

  return verifiedSteps;
}

export const accountIsNotVerified = (verifiedSteps, isDriver) => {
  const driverSteps = verifiedSteps.filter(step => step.isDriver);
  const hostSteps = verifiedSteps.filter(step => step.isHost);

  const accountNotVerified = isDriver ? driverSteps.some(step => !step.verified) : hostSteps.some(step => !step.verified);

  return accountNotVerified;
}


export const perInsuranceValue = (listing)=>{

  const marketValue = listing?.attributes.publicData.marketValue || 0;
  let insuranceAmount = 0;
  const make =  listing?.attributes?.publicData?.make
  const model = listing?.attributes?.publicData?.model;

  
  console.log('make', make);
  console.log('model', model);

  
  if (marketValue) {
    const referenceValue = parseInt(marketValue) * 0.6;

    if (referenceValue < 100000) {
      insuranceAmount = 17000;
    }

    if (referenceValue >= 100000 && referenceValue <= 200000) {
      insuranceAmount = 17000;
    }

    if (referenceValue >= 200000 && referenceValue <= 300000) {
      insuranceAmount = 19000;
    }

    if (referenceValue >= 300000 && referenceValue <= 400000) {
      insuranceAmount = 21500;
    }

    if (referenceValue >= 400000 && referenceValue <= 500000) {
      insuranceAmount = 25500;
    }

    if (referenceValue >= 500000 && referenceValue <= 1000000) {
      insuranceAmount = 29500;
    }

    if (referenceValue > 1000000) {
      insuranceAmount = 34500;
    }
  }

  const restrictedMakes = [
    'ACURA', 'ALFA ROMEO', 'VENTURE', 'ASTON MARTIN', 'BENTLEY', 'CHANA', 
    'BUICK', 'CADILLAC', 'ROAD MASTER', 'DAEWOO', 'DAIHATSU', 'EAGLE', 'FERRARI', 'GEO', 
    'GMC', 'IVECO', 'JAGUAR', 'KAWASAKI', 'LADA', 'LAMBORGHINI', 'FLEXI-AC', 'MASERATI', 
    'MERCURY', 'OPEL', 'PLYMOUTH', 'PONTIAC', 'ROLLS ROYCE', 'ROVER', '4K', 'SATURN', 
    'CARMOSINO ANHANGER', 'SKODA', 'BAJAJ', 'RIKY', 'JCB', 'GATO', 'MG', 'AUTOCAR', 
    'AUSTIN', 'SUPER TUCAN', 'HOWO', 'MORRIS', 'SAMSUNG', 'TALBERT', 'SANYANG', 'ZEEKR', 
    'GREAT DANE', 'STRICK', 'CHESH', 'GALION', 'TRINITY', 'LUBBOCK', 'KAGER BEAVER', 
    'APACHE', 'INGERSOLL', 'LEVDEO', 'SCHWING', 'KONECRANES', 'HYSTER', 'CHEVY', 
    'VOGELE SUPER', 'QING', 'LORAIN', 'TATA', 'TRAIL KING', 'WHITE', 'HAIMA', 'AA', 
    'VOLQUETE', 'WRANGLER', 'TRANS', 'POLARIS', 'LANCIA', 'RM', 'SUR-RON', 'MOKE', 
    'P H', 'WESTERN', 'ROADSTER', 'OTROS', 'FLAT BET', 'AZTEC', 'KAWEI', 'XC MG', 
    'LOTUS', 'HUDSON', 'SKYWELL', 'FELLING', 'STAR EV', 'ZOOMILON', 'ASTRA', 'KAIYI', 
    'A.T.V.', 'TURANO', 'COLT', 'SAKAI', '3-STAR', 'ALTINORDU', 'HENAN DINISI', 
    'ZXMCO', 'JCT', 'BUGATTI', 'PAGANI', 'TRUCK', 'VOLQUEBO', 'UTILITY', 'CHALLENGER', 
    'AVA', 'POLESTAR', 'LINHAI', 'ANTONIO CARRARO', 'ECO GO', 'HAVAL', 'VARICA', 
    'DAVINO MASTER', 'VOLCON', 'MONT SEMI', 'XPENG', 'C.M.C.', 'BAW', 'STAR WAY', 
    'MANAC', 'BLAW KMOX', 'ARCTIC CAT', 'TRAIMOBILE', 'FAW', 'STERLING', 'OSKOSH', 
    'HUMMER', 'CRANE CARRIER', 'CONTINENTAL', 'TAYLOR', 'DAF', 'AMERICAN', 'LIFAN', 
    'LEAP MOTOR', 'POLAR', 'TENNANT', 'COSANCA', 'CNJ', 'OTAWA X', 'A CARDINAL', 
    'LONCIN', 'CHANGAN', 'CLUB CAR', 'GREAT WALL', 'NEW CAPACITY', 'NIPPONIA', 
    'GONOW', 'KYC', 'KINGSTAR', 'GACELA', 'MANITOWOC', 'KANGBA', 'DEMAG', 'PALFINGER', 
    'WABA', 'CHAPMAN', 'SCION', 'SANGYANG', 'IR', 'IMECOL', 'AMBASSADOR', 'VM-AGRIP', 
    'MONGAN', 'ACADIAN', 'FORLAND', 'BUGGY', 'RAMP-STAR', 'WINDONG', 'UAZ', 'STRI', 
    'LODC', 'BUDC', 'WULING', 'WORKHORE', 'PACKART', 'GAC', 'MAXUS', 'TROY', 
    'CHERY', 'ZX AUTO', 'SANY', 'AUSTIN ROVER', 'EZGO', 'WALDOM', 'X1000', 'KAISER', 
    'LAND', 'OM', 'YUKON', 'YUEJIN', 'TIANMA YUHU', 'YANGZI', 'BEET', 'SYM', 
    'TITAN', 'CHANGHE', 'RSM', 'HAFEI', 'STAR FORCE', 'WHITEGNC', 'LIEBHERR', 
    'FURGON', 'HISUN', 'TIANJIN', 'MIGRO COMPACT', 'PIERCE', 'B Y D', 'KANDI COCO', 
    'CMC', 'FEATHER', 'CAKY', 'SPECT', 'CHART', 'LUXGEN', 'DTS', 'HUANGHAI', 
    'UD TRUCKS', 'XINKAI', 'TICO', 'BAJAC', 'METAGRO', 'GOOSENECK', 'TAURO', 
    'CAN AM', 'MCLAREN', 'BERING', 'KW', 'HIGER', 'SANDRINE', 'CH', 'MAHINDRA', 
    'ALKANE', 'RAM', 'DONG FENG', 'OTTAWA', 'RANGER', 'LIGIER', 'WESTERN STAR', 
    'PUZMEISTER', 'DIMEX', 'ORA', 'KALMAR', 'MIA', 'JONWAY', 'SEM', 'MARCA NO DEFINIDA', 
    'CARRITO DE GOLF', 'MONTACARGAS', 'FURGONES NACIONALES', 'FURGONES EXTRANJEROS'
  ];

  const restrictedModels = [
    'ZZ5371VDMB32101', 'ZZ3257N3847A', 'ZZ1317N3667A', 'ZX680020', 'ZX-1100', 'ZX', 
    'ZS150-7', 'ZS125GY-A', 'ZS125GY-A', 'ZS125-47', 'ZS110-9', 'ZR-250', 'ZR', 
    'ZOOM SUM 150CC', 'ZN6440V1B4', 'ZKFWSZH', 'ZK6908H9', 'ZK6898H', 'ZK6809HA', 
    'ZK6800D', 'ZK6729D', 'ZK6147H', 'ZK6132H', 'ZK6129H', 'ZK610HA', 'ZK6107NAJ', 
    'ZK6107HA', 'ZIP', 'ZFORCE', 'ZF12GY', 'ZD', 'Z8', 'Z7', 'Z650 ABS', 'Z4', 
    'Z3', 'Z3', 'Z3', 'Z1000', 'YZF', 'YZ450F', 'YZ250', 'YXZ1000ETZ', 'YXR700F', 
    'YXR660F', 'YX100', 'YW125ME', 'YW125', 'YW100S', 'YW100', 'YUTONG', 'YUNA 150', 
    'YUKON', 'YUKON', 'YUKON', 'YS125M', 'YM250', 'YJ-RADAR RD6', 'YFZ450', 'YFS200', 
    'YFM700FWAD', 'YFM660R', 'YFM660FWA', 'YFM450FWA', 'YFM450FWA', 'YFM450FAS', 
    'YFM400FWA', 'YFM350X', 'YFM350R', 'YFM350A-05', 'YFM350A', 'YFM125A', 'YFM 700', 
    'YFM 250', 'YFA125', 'YDRA', 'YD110-09', 'YD1100', 'YD110', 'YBR 125Z', 
    'YBK125ED-08', 'YB125ED-09', 'YB125ED-07', 'YB125ED', 'YB-125', 'YB 125ZR', 
    'YAXI', 'YA90', 'YA80', 'Y110', 'XY90-2', 'XY250GY-6B', 'XY200GYH', 'XY200-GY-8', 
    'XY125GY11', 'XY125-10D', 'XY110V-A', 'XWOLF', 'XVS650', 'XVS13UB', 'XVS13AD', 
    'XVS', 'XVR100', 'XV23A-DP-1S-Z2P10-FC-AB-RA', 'XV', 'XTZ125E', 'XTRAINER 250CC', 
    'XTERRA', 'XT660X', 'XT600E', 'XT250GK-7', 'XT250CCGXT', 'XT', 'XS125T-11', 
    'XS125-F', 'XROAD 200', 'X-RIDER', 'X-RIDER', 'XR650L', 'XR400RI', 'XR-250', 
    'XR190L', 'XR150', 'XR125L', 'XR', 'XR', 'XPULSE', 'XPRESS', 'XMQ6902Y', 
    'XMQ6900Y', 'XMQ69004', 'XMQ6800Y', 'XMQ6552HED4', 'XMQ6209Y', 'XMQ6129', 
    'XMQ6117Y', 'XMQ6000Y', 'XML6558X8', 'XML 6549', 'XMAX 250', 'XLR', 'XL650V4', 
    'XL', 'XK8', 'XK R', 'XJR', 'XJR', 'XJR', 'X-HOT', 'XGJ200-23', 'XF', 
    'XERO GT5', 'XD HATCHBACK', 'XCITING 500', 'XCITING 400I', 'XCD-125', 'XC115B', 
    'XC 100', 'XB', 'XA125', 'X-90', 'X6 SK250', 'X53', 'X525D', 'X200', 'X200', 
    'X1000', 'WY-CG150', 'WY125T-9', 'WRANGLER SAHSARA LIMITED', 'WRANGLER SAHARA UNLIMITED', 
    'WRANGLER RUBICOM', 'WRANGLER RIBICOM', 'WRANGLER', 'WRANGLER', 'WR450F-E', 
    'WR250F', 'WOLF - 150', 'WOLF', 'WINDSTAR', 'WINDSTAR', 'WINDSTAR', 'WARRIOR', 
    'WAGON', 'VZR 180', 'VULCAN', 'VUE', 'VTX', 'VTR', 'VTR', 'VT750C', 'VT6', 
    'VS15W8', 'VS150', 'VS125', 'VS 150', 'VR 150', 'VORREI 150', 'VOLARE', 
    'VN1500 P1', 'VMAX1200', 'VL', 'VIVA 125', 'VISTA', 'VISION', 'VIRAGO', 
    'VINSON 500', 'VINO', 'VILLAGER', 'VILLAGER', 'VIKING VI', 'VIEW', 'VIBE', 
    'VIAGGIO', 'VG', 'VFR800', 'VESPA LX250', 'VESPA 250', 'VESPA 200', 'VESPA', 
    'VERYCA', 'VERSYS ABS', 'VERSA', 'VERISA', 'VERACRUZ', 'VENZA', 'VENUS', 
    'VENTURE', 'VENTURE', 'VENTURE', 'VELOSTER', 'VECTRA', 'VC150FS-10D', 'VANAGON', 
    'VAN', 'VAN', 'VAN', 'VAN', 'VAN', 'VAN', 'V92', 'V85TT', 'V80S', 'V80S', 
    'V80', 'V80', 'V80', 'V70', 'V70', 'V250', 'V125', 'URVAN', 'URVAN', 'URBANO', 
    'URBAN', 'URBAN', 'URBAN', 'URBAN', 'URBAN', 'UR110EZL7', 'UNIVERSE', 'UNICITY', 
    'UBS69GATD', 'UB125EE', 'UA30A-A', 'U2011P3T1POSX', 'TZR', 'TX-350', 'TX200', 
    'TWX420FAC', 'TWIN 350', 'TW250', 'TW225', 'TW-200-07', 'TW200', 'TW', 
    'TVMXER 250', 'TURISTAR', 'TURISTAR', 'TURING', 'TUNDRA TRD HIBRIDA', 'TTR 250', 
    'TT250-RAID', 'TT XTZ125E', 'TT ROADSTEN', 'TT COUPE', 'TT', 'TS125XC', 
    'TS125ERW', 'TRX650FGA', 'TRX500FA1', 'TRX420TED', 'TRX3Q0FWY', 'TRX350', 
    'TRX250', 'TRX', 'TRUCK DELUXE 200', 'TRIBECA', 'TRIAL', 'T-REX-150', 
    'T-REX', 'TRENDY', 'TRAXTER', 'TRANSPORTER', 'TRANSPORTER', 'TRANSIT (AUTOBUS)', 
    'TRANSIT', 'TRANSIT', 'TRANSIT', 'TRANSIT', 'TRANSIT', 'TRAJET', 'TRAILER HORSE', 
    'TRAILER', 'TRAILER', 'TRAIL BOSS', 'TQ 3 PAX', 'TOYOTA', 'TOYOTA', 'TOYOTA', 
    'TOYOTA', 'TOWN-CAR', 'TOWN-CAR', 'TOWN ACE', 'TOWN ACE', 'TOWN ACE', 'TOW', 
    'TOUGH', 'TORRES', 'TORNADO', 'TNT', 'TN200X', 'TM250F1', 'TL1000R', 'TL', 
    'TL', 'TL', 'TK50QT4', 'TK', 'TK', 'TITAN', 'TIPO', 'TIGUER 800', 'TIGGO 8 PRO', 
    'TIGGO 7 PRO', 'TIGGO 4', 'TIGER', 'TICO', 'TICO', 'TIBURON', 'THVG', 
    'THUNDERBIRD', 'THUNDERBIRD', 'THUNDER 200', 'THOMAS', 'TF', 'TERRALORD AT', 
    'TERRACAN', 'TERRA TRANSPORT', 'TERIOS', 'TELLURIDE', 'TEANA', 'TE-450', 
    'TE310', 'TE300', 'TE250', 'TDI', 'TC-85', 'TC-2000A', 'TC', 'TAYCAN', 
    'TARGET', 'TARGA 4S', 'TANK', 'TANG EV HONOUR', 'TALON 1000R', 'TACTIC 550', 
    'TACT', 'T99', 'T8', 'T77', 'T660', 'T6', 'T55', 'T3B', 'T33', 'T30', 
    'T30', 'T30', 'T-20', 'T-110-S', 'T110C', 'T105E-05', 'T105E', 'T105', 
    'T', 'SY6548J1S3BH', 'SY648033DGB-HW-PE', 'SY215C', 'SXZ 1047', 'SX3255DV384C', 
    'SX32556V384C', 'SX1255JR464C', 'SX1', 'SX 3', 'SX 2', 'SVX', 'SVX', 
    'SVX', 'SUPRA', 'SUPRA', 'SUPER-SALOON', 'SUPER-SALOON', 'SUPER KINGKONG', 
    'SUPER DUTY', 'SUPER DUTY', 'SUPER CUB', 'SUPER CABALLO', 'SUPER 9', 
    'SUPER 9', 'SUPEAEROCITY', 'SUNRAY', 'SUNNIY 100', 'SUBURBAN', 'SUBURBAN', 
    'SUBURBAN', 'SUBURBAN', 'SUBURBAN', 'SUBN', 'STYLE', 'STX', 'STRYKER', 
    'STRIKE 250', 'STRIKE', 'STREETFIGHTER 848', 'STREAM', 'STRATUS', 'STK', 
    'STIAT', 'STIAT', 'STERGO', 'STEPWAY HATCHBACK', 'STEEM', 'STARLET', 
    'STAREX', 'STAR 5', 'STAR 5', 'ST-150-02A', 'ST150-02A', 'ST.4', 'ST 250', 
    'ST', 'SST', 'SRCTPDRB0345T', 'SR400', 'SR2 175', 'SR2', 'SQ5', 'SPYDER', 
    'SPRINTER', 'SPORT-VAN', 'SPORT-VAN', 'SPORT-VAN', 'SPORTSMAN', 'SPORT-125', 
    'SPORT ES 100', 'SPORMAN', 'SPLASH', 'SPIRIT', 'SPIKE', 'SPIDER', 'SPIDER', 
    'SPEEDY 100', 'SPECTRA', 'SPARK', 'SOUL', 'SONIC', 'SONATA', 'SOLSTICE', 
    'SOLARA', 'SMX-200', 'SMASH 110', 'SMART', 'SMART', 'SM6', 'SM1 250CC', 
    'SM 610', 'SLK55', 'SLK230K', 'SLK200', 'SLK 280', 'SLK', 'SLK', 'SLING SHOT', 
    'SL65', 'SL500', 'SL500', 'SL150T', 'SL', 'SL', 'SL', 'SL', 'SKYWAVE', 
    'SKY', 'SKY', 'SKIPPER', 'SK150-9', 'SK150-8', 'SILHOUETTE', 'SILHOUETTE', 
    'SIENNA', 'SIENNA', 'SIENNA', 'SHADOW', 'SH125', 'SG200GY-2', 'SG125-5', 
    'SG-100A', 'SEROW', 'SERENA', 'SERENA', 'SENATRA2GM4', 'SEDONA', 'SECTOR 750', 
    'SE - ELECTRICO', 'SCV110FH DIO', 'SCRUM', 'SCRAMBLER', 'SCOOTER-ED-MIKU', 
    'SCOOTER POWERMAX 125', 'SCOOTER', 'SCOOTER', 'SCOOTER', 'SCOOTER', 
    'SCOOTER', 'SCOOPY', 'SCION', 'SCHOOL BUS', 'SC6372', 'SC - 400', 'SC', 
    'SC', 'SC', 'SB', 'SAVANA', 'SAVANA', 'SAVANA', 'SANYANG', 'SANYANG', 
    'SANYANG', 'SANICO', 'SAMURAI', 'SAMURAI', 'SAF-LINER', 'SAFARI', 'SAFARI', 
    'SA26J', 'S85LVZMDK', 'S8', 'S7', 'S65 AMG', 'S63AMG', 'S600', 'S600', 
    'S6', 'S560', 'S550L', 'S550', 'S500L', 'S500', 'S5', 'S430', 'S-420 L', 
    'S400L', 'S-400', 'S4', 'S350L', 'S-350', 'S320L', 'S3', 'S3', 'S2000', 
    'S200', 'S1800', 'S1800', 'S.P.A.', 'S 580', 'S 550', 'S 320', 'S 320', 
    'S', 'S', 'S', 'RZR', 'RZR', 'RZ', 'RYKER', 'RY90', 'RY150T-40', 'RXZ100', 
    'RX-S115', 'RX-S', 'RX-R200', 'RX-8', 'RX-7', 'RX-7', 'RX-100', 'RX100', 
    'RX R150', 'RX 2650', 'RX 200', 'RX 115', 'RX 100', 'RX', 'RUSH', 
    'RUNNER 500', 'RUNNER 500', 'RUBICON', 'RTR 180', 'RSV4 1000 RR', 'RSQ8', 
    'RS7', 'RS4', 'RS3', 'RS-150', 'RS 100', 'RR600', 'RR4T', 'RR480', 
    'RR250-RAID', 'RR 4T 350CC', 'RR 2T 300 CC', 'ROYAL STAR', 'ROSA', 
    'ROSA', 'RONDO LX', 'ROMA', 'ROCAR', 'RM-Z450M1', 'RMZ 250', 'RKV', 
    'RK-150', 'RHINO', 'REXTON', 'RETRO', 'RENO', 'RENEGATE 250 T', 
    'RENEGADE 200', 'RENEGADE', 'RENDEZ VOUS BT 26', 'RELAY 3', 'REGAL', 
    'REFINE-CONATRA', 'REFINE', 'RE4S', 'RE MAXIMA', 'RE 4S', 'RD-6', 
    'RD', 'RCZ', 'RC-F', 'RB150-T', 'RAY-Z', 'RAYO 150', 'RAYDER', 
    'RAPTOR 650', 'RAPTOR', 'RANSHEE', 'RANGER RZR', 'RANGER CREW', 
    'RANGER CREW', 'RANGER 1000', 'RAIDER', 'RACTIS', 'RACING EXTREMD TTX', 
    'RABBIT', 'R8', 'R-6', 'R-500', 'R5 200', 'R-350L', 'R300L', 'R30', 
    'R1T', 'R1S', 'R12GA6X4NZ', 'R1200RT', 'R1', 'R 350', 'R 350', 
    'QUTEBAJAJ', 'QUEST', 'QUEST', 'QUEST', 'QUAD RAIDER 600', 'QUAD LANDER', 
    'QQ3', 'QM50-T', 'QM50 QT-6A', 'QM250ATV-2B', 'QM250 ATV', 'QM200GY-B', 
    'QM200GY', 'QM200 GY', 'QM125T-10V', 'QM125T-10', 'QM125T', 'QM125T', 
    'QM125T', 'QM125-A', 'QM125-10V', 'QM110-3F', 'QM110-3F', 'QJ125T-12C', 
    'QJ100-M', 'Q70', 'Q60', 'Q2L', 'PX72', 'PX', 'PUMA', 'PULSAR', 
    'PULSAR', 'PT CRUISER', 'PRUSS', 'PRUEBA', 'PRUEBA', 'PRUEBA', 
    'PRUEBA', 'PROTON WYRA', 'PROTON WYRA', 'PROSPECT', 'PROBOX', 
    'PRO x 250', 'PRINCE', 'PRIMI MOVER', 'PRIME MOVER', 'PRIMASTAR', 
    'PRIMASTAR', 'PRESS CUB 110', 'PREGIO', 'PREGIO', 'PRAIRIE', 
    'POWER DALY A50.12', 'POWER DAILY A50', 'PORTER', 'PMX', 'PLEO', 
    'PLATZ', 'PLATINA', 'PLACAS DE EXHIBICION', 'PLACAS DE EXHIBICION', 
    'PLACAS DE EXHIBICION', 'PIXIS', 'PIXIS', 'PITBULL', 'PIONNER', 
    'PICKMAN', 'PIAGGIO GTS 250', 'pia 9.150', 'PERLA', 'PEOPLE 250', 
    'PCX-150', 'PC300LC-8', 'PB405', 'PB', 'PASSO', 'PASAJ', 'PASAJ', 
    'PARADISO', 'PANIGALE', 'PANAMERA TURBO', 'PANAMERA HIBRIDO', 
    'PANAMERA', 'PACE ARROW', 'P-31', 'P-31', 'P15WHLNLL', 'P15WHLNL', 
    'P15WHLNL', 'OUTLANDER MAX 400', 'OUTLANDER', 'ORLANDO', 'ORBIT 125', 
    'OPIRUS', 'OLIVER 125', 'OF-1721', 'OC 500 LE', 'O500', 'NXR125', 
    'NXR 125 BROS', 'NXR', 'NX4', 'NX FALCON 400', 'NX', 'NVA', 
    'NV-350', 'NV-200', 'NV-200', 'NV-200', 'NV-200', 'NV1500', 
    'NV100', 'NV 250', 'NV 250', 'NT700', 'NT', 'NT', 'NT', 
    'NQRM1K-22', 'NQR71RL', 'NQR71R-02', 'NQR71P-02', 'NQR71P', 
    'NQR71L-LN5VAY', 'NQR71L-KN5VAY', 'NQR71L-22', 'NQR71K22', 
    'NQR', 'NPR71L-HJ5VAY', 'NPR71L-HJ5VAA', 'NPR71L-FL5VAD1', 
    'NPR71H-26', 'NPR71F-28', 'NPR71F-20', 'NPR71F-02 DUMP', 
    'NPR66P02', 'NPR66L-34', 'NPR66L-32', 'NPR59PN', 'NPR', 
    'NOTE', 'NOTE', 'N-MAX', 'NK150', 'NJL6601C', 'NITRO', 
    'NISSAN', 'NISSAN', 'NISSAN', 'NISSAN', 'NIRO EV NOBLESSE', 
    'NIRO', 'NINJA', 'NH', 'NEXUS', 'NEO-NX 110', 'NEON', 
    'NC-90', 'NC-110', 'NC 125', 'NB-150X', 'NB125E', 'NB BRIO 125', 
    'NAVEGATOR', 'NATIVA 125', 'N400', 'N400', 'N300', 'N300', 
    'N 1200', 'MYSTIQUE', 'MXU300', 'MXU 375', 'MX-5', 'MX-5', 
    'MUSTANG MACH E', 'MUSTANG', 'MUSTANG', 'MUSSO GRAND LIMITED', 
    'MULTIVAN', 'MULE KAF400B9F', 'MULE 750', 'MULA', 'MT6', 
    'MT 55', 'MS-300', 'MS125', 'MR2', 'MR2', 'MR2', 
    'MPV REFINE', 'MP3-250', 'MP DM150', 'MOVING-125', 'MOVI', 
    'MOTOCICLETA', 'MOTOCICLETA', 'MOTOCICLETA', 'MOTOCICLETA', 
    'MOTOCARRETA BOLT', 'MOTOCARGA', 'MORNING', 'MONTER796', 
    'MONACO', 'M-NV VERSION CHINA', 'ML270', 'ML270', 'MKX', 
    'MK155', 'MIRO130', 'MIRO 110', 'MIRAGE', 'MIRA', 
    'MIO', 'MINT', 'MINIBUS TECHO ALTO', 'MINIBUS', 'MICRA', 
    'MIATA', 'MG124-7', 'MF2635', 'METRO KWMOTOR', 'MENE', 
    'MEGANE', 'MDX', 'MD90', 'MD70', 'MD50', 'MD30', 
    'MC 250', 'MC 200 ZH-6', 'MC', 'MB55', 'MB-100', 
    'MAXIMA', 'MAX QM125-10V', 'MAX POWER 150', 'MAX LTD OUT BLU', 
    'MAX LTD FOUR WEELS', 'MAX 125', 'MAX', 'MAVERICK', 
    'MATRIX', 'MATERIA', 'MASCARELLO', 'MARVEL', 'MARVEL', 
    'MARCOPOLO', 'MARCOPOLO', 'MARAUDER', 'MALIBU', 'MAGNUM SXT', 
    'MAGNETIC 125', 'MAGNA', 'MAGNA', 'MAGIC-110', 'MAGIC 110', 
    'M900', 'M850I XDRIVE', 'M-80', 'M696', 'M6', 
    'M5', 'M5', 'M5', 'M440 XDRIVE', 'M4', 
    'M38', 'M3', 'M125-2A', 'LZW6371', 'LXT13', 
    'LX90', 'LX700 UTV', 'LX250ZH-13', 'LX250 ATV-F', 
    'LX200ZH-AI', 'LX200ZH-10A', 'LX200ATV-U', 'LX200ATV-2', 
    'LX200 GY', 'LX175T-14B', 'LX150', 'LX110-12A', 
    'LX100', 'LX 50', 'LX 125', 'LUMINAAPV', 'LUMINA', 
    'LUMINA', 'LUMINA', 'LUMAX', 'LT-Z90K9', 'LT-Z250K4', 
    'LT-F400FK2', 'LT-F250Y', 'LT-F250K4', 'LT-F250K2', 
    'LT-F160K3', 'LT-A750XPL5', 'LT-A750XK9', 'LT-A750XK8', 
    'LT-A700XK7', 'LT-A500XL8', 'LT-A500XL5', 'LTA500XL4', 
    'LT-A500XL2', 'LT-A500FK6', 'LT-A500FK2', 'LT-A500FK1', 
    'LT-A400FM3', 'LT-A400FL2', 'LT-A400FK9', 'LT-A400FK8', 
    'LT-A400FK7', 'LT-A400FK6', 'LT-A400FK5', 'LT-A400FK2', 
    'LT-A100FK5', 'LT-700', 'LT-400FK', 'LOYALE', 'LOYALE', 
    'LOTZE', 'LOGO', 'LJA134GI', 'LITTLE CUB', 'LITE-ACE', 
    'LITE-ACE', 'LITE-ACE', 'LINCE-125', 'LIGTH BEE X', 
    'LIGHT TRUCK', 'LIGHT BEE X', 'LIGERO RS', 'LHD', 
    'LH202L-REMDE', 'LH202L-REMDE', 'LH114L', 'LF5028XXY', 
    'LF200-16C', 'LF125-5', 'LF110-26H', 'LF', 'LF', 
    'LEON', 'LEAD-150', 'LEAD 90', 'LEAD 50', 'LEAD 100', 
    'LEAD', 'LEAD', 'LEAD', 'LD150T-C', 'LCK6935H', 
    'LCK6570D4X', 'LCK6137H', 'LCK6128H', 'LCK6127H', 
    'LCK6125H', 'LCK6103GC', 'LC-4', 'LC 500', 'LBBOII', 
    'LB150T-12', 'LB', 'LAUTAR', 'LAUREL', 'LAUREL', 
    'LAUREL', 'LANCER', 'LANCER', 'LAFESTA', 'LAEKEZ', 
    'LABO', 'L7', 'L200', 'L200', 'L200', 
    'L125S', 'KX161', 'KX', 'KVF750', 'KVF700', 
    'KVF400-D3', 'KVF360B6F FOUR WHEEL', 'KVF 360', 'KVF 300', 
    'KTF-250', 'KSF90ABF', 'KRT800JPFNN', 'KRT800CFF', 
    'KRF750PAF', 'KRF750PAF', 'KRF1000', 'KPR-200', 
    'KOREMOTO', 'K-ONE', 'KODIAK 700', 'KODIAC', 
    'KNIGHT', 'KMX', 'KM200-T', 'KM110W', 'KLZ 1000 BFFA', 
    'KLX250D-TRAKER', 'KLR650', 'KLQ6896Q', 'KLQ6796Q', 
    'KLQ6796AE3', 'KLQ6600C', 'KLQ6132', 'KLQ6129Q', 
    'KLQ6119QE3', 'KLQ57960', 'KLM616', 'KLF220-A14', 
    'KLE', 'KL', 'KINGRANCH', 'KINGQUAD', 'KING-GS', 
    'KING THREE-WHEELS LS', 'KINETIC K4-100', 'KIA', 
    'KEYSTO', 'KEF300-A4', 'KEEWAY', 'KEE110', 
    'KB 100 RTZ', 'KAT820BPFNN', 'KAF400HJF', 'KAF300', 
    'K94', 'K915B', 'K7', 'K-5', 'K410', 
    'K-3888240167', 'K360', 'K310IB4X2NB', 'K-310', 
    'K2', 'K15', 'K14-001', 'K124', 'K-114', 
    'K-113T', 'K100', 'K 1600 GT', 'JUMPER', 
    'JUMPER', 'JUMPER', 'JUM BUS', 'JUKE', 
    'JS125-5', 'JS110-B', 'JR-80', 'JP90-1', 
    'JP150GY-2', 'JOURNEY SXT', 'JOURNEY', 'JOKER', 
    'JOHN COOPER WORKS', 'JOHN COOPE', 'JOG 150', 
    'JOG 150', 'JOG', 'JOE', 'JNSZ110TR', 
    'JMD', 'JIMNY', 'JIALING', 'JH70', 
    'JH100', 'JET4-150', 'JET 14', 'JET 125', 
    'JE493ZLQ4', 'JDC', 'JCB170', 'JC31', 
    'JC300ZH', 'JC150-20', 'JB110-B', 'J125', 
    'IVECO', 'ISMART', 'ISIS', 'IS-F 350', 
    'IS-350', 'IS300', 'IS-250', 'IS200', 
    'IQ', 'IONIQ', 'ION', 'INTREPID', 
    'INTEGRA', 'INTEGRA', 'INTEGRA', 'IMPREZA WRX', 
    'IMPREZA WRX', 'IMPREZA WRX', 'IMPALA', 
    'IE50FMG-A1C57209', 'ID.6 PRO VERSION CHINA', 
    'ICON YELLOW', 'ICCO', 'I8', 'HZB50L-ZGMSS', 
    'HYPERSPORT', 'HYPERMOTARD', 'HYONSUNG', 
    'HX520SL', 'HX300', 'HX220', 'HX', 
    'HV15NA-6', 'HUSKY', 'HUNK', 'HUMMER 300', 
    'HTA250ZH', 'HTA250HZ', 'HTA150T-5', 
    'HTA150T-23', 'HTA150T-18', 'HTA150-20', 
    'HTA125T-3', 'HP4', 'HOWO ZZ1047', 
    'HM125-7 ROOSTER', 'HM105-B', 'HK8124AM', 
    'HJ125-7', 'HJ125-7', 'HJ125-26', 'HIJET', 
    'HIJET', 'HIJET', 'HIJET', 'HIJET', 
    'HIJET', 'HIJET', 'HIGH BALL', 'HI-ACE', 
    'HI-ACE', 'HI-ACE', 'HI-ACE', 'HIACE', 
    'HI ACE', 'HI', 'HI', 'HHR', 
    'HG 190', 'HFC6591', 'HFC1040K', 'HERCULES ZS200GY', 
    'HELIX', 'HDX 700 CREW XT', 'HD-150 K', 
    'HAYABUSA', 'HARLEY', 'HAMMER HEAD', 
    'HAISE MINIBUS', 'HA02', 'H6C', 'H5F', 
    'H5C', 'H350', 'H2L DELUXE', 'H2L (DELUXE)', 
    'H2L', 'H250', 'H1 TQ', 'H-1', 
    'H-1', 'H-1', 'H-1', 'H-1', 
    'H008', 'H 1 SVX', 'H 1 SVX', 
    'GY-200', 'GY200', 'GY-150-3A', 'GY150-3', 
    'GY 250 RAC', 'GY 200', 'GY', 
    'GX6', 'GX3', 'GTV', 'GTS25L', 
    'GTS 250', 'GTR', 'GTI', 'GT4', 
    'GT3', 'GT2', 'GT AMG S', 'GT', 
    'GT', 'GSX-R600', 'GSX-R', 'GSX125', 
    'GSX1000', 'GSX', 'GRUNT ELECTRIC', 
    'GRIZZLY', 'GRENADIER', 'GRECALE MODENA', 
    'GREAT DANF-GPS-241', 'GREAT DANF-GPS 241', 
    'GRAT DANF', 'GRANDEUR', 'GRANDEUR', 
    'GRANDEUR', 'GRAND-CARAVAN', 'GRAND-CARAVAN', 
    'GRAND STAREX', 'GRAND STAREX', 'GRAND MICRO S2', 
    'GRAND CHEROKEE TRACKHAWK', 'GRAND CARAVAN', 
    'GRAN VIA', 'GRAMBIRD', 'GRACE', 
    'GRACE', 'GR 150', 'GP300', 
    'GOLF GL', 'Golf Car', 'GOLF', 
    'GOKART', 'GO VAN PAX', 'GN125H', 
    'GN', 'GMAX', 'GLORY 500', 
    'GLC250 COUPE', 'GLC 63', 'GLADIATOR', 
    'GLADIATOR', 'GLA 35 4MATIC', 'GL1800 GOLD WING', 
    'GL1800 GOLD WING', 'GL 150-13', 'GIULIA', 
    'GIORNO CUB', 'GEV', 'GETZ', 
    'GENESIS', 'GENESIS', 'GEELY', 
    'GD110HU', 'GC416GXA96098', 'GB-819', 
    'GALANT', 'GA5023XXYSE4/S9F-84', 'GA 300', 
    'GA 200', 'GA 125', 'G9', 
    'G63 AMG', 'G-550', 'G500', 
    'G400 CDI', 'G25E-3', 'G110DL', 
    'G110 DL', 'G01F', 'FZS1000', 
    'FZN150D', 'FZ6', 'FZ16', 
    'FZ-09', 'FXDC', 'FX-120', 
    'FUTONG', 'FUSION', 'FULLBACK', 
    'FULL THROTTLE', 'FU EB HS N2', 'FSC60', 
    'FS65', 'FS 85', 'FS 65', 
    'FS 150', 'FS 150', 'FRATELLI', 
    'FR-16', 'FR100-3A', 'FOURWHEEL', 
    'FOUR WHEELS', 'FOUR WHEEL', 'FOUR WHEEL', 
    'FOUR WHEEL', 'FOUR WHEEL', 'FOUR WHEEL', 
    'FORZA', 'FORWELL', 'FORTWO', 
    'FORTHING', 'FORTE', 'FORGE 400', 
    'FORFOUR', 'FORENZA', 'FORCE', 
    'FORCE', 'FOCUS SE 4X2', 'FOCUS', 
    'FLEX 110', 'FLAME-SR-125', 'FK-90', 
    'FK110-6A', 'FJ70', 'FJ CRUISER', 
    'FIT ARIA', 'FIT (SPORT)', 'FIESTA HATCHBACK', 
    'FIERO 110', 'FIDDLE 125', 'FG150D', 
    'FESTIVA', 'FERRA 110', 'FENG', 
    'FE', 'FAW', 'FAW', 
    'FA200', 'F7403W2', 'F-7', 
    'F56', 'F-530', 'F-350', 
    'F-350', 'F350', 'F3 200', 
    'F-150 RAPTOR', 'F1', 'F 5S', 
    'EZIO', 'EZGO', 'EXPRESS-VAN', 
    'EXPRESS-VAN', 'EXPRESS-VAN', 'EXPRESS', 
    'EXPRESS', 'EXPRESS', 'EXPRESS', 
    'EXPLORER', 'EXPEDITION KING RANCH 4X4', 
    'EVOLUTION 150', 'EVOLUTION 110', 
    'EVOLUCION VI', 'EVERY', 'EV3', 
    'EV', 'EUROVAN', 'EUROVAN', 
    'EUROVAN', 'EUROCARGO MLI80', 'EU', 
    'E-TRON', 'E-TRON', 'ET5', 
    'ET150-A', 'ET-125T-12', 'ET-125', 
    'ET110', 'ET10004', 'ET-100', 
    'ET100', 'ESSEESSE', 'ES V80S', 
    'ES 700', 'EQUATOR SPORT', 'EQS', 
    'EQ6400LF', 'EQ6380LF HIBRID', 'EQ5025', 
    'EPICA', 'EOS', 'ENVI', 
    'E-NS1', 'ENP 1', 'ENDURO 250 SIX DAYS', 
    'ENDURO 250 2T', 'EN-150', 'ELITE', 
    'ELEMENT', 'EG8', 'EG11', 
    'EF-10', 'ECONOLITE E-350', 'ECONOLITE E-350', 
    'ECONOLINE', 'ECONOLINE', 'ECONOLINE', 
    'ECONOLINE', 'ECOLINE E-150', 'ECOLINE E-150', 
    'ECO-FIT100', 'ECO PLUS', 'ECO', 
    'ECLIPSE', 'ECLIPSE', 'ECLIPSE', 
    'ECHO', 'EC31', 'EC300 DL', 
    'EC-300', 'EC220 DL', 'EC REPLICA CERVANTES', 
    'EC 60', 'EC 30', 'EC', 
    'E63 S', 'E63', 'E53 AMG', 
    'E-450', 'E-450', 'E43', 
    'E-4', 'E-350', 'E-350', 
    'E-350', 'E-350', 'E-35', 
    'E-35', 'E 55', 'E 430', 
    'E 200 NGT', 'DYNASTY', 'DY125', 
    'DX', 'DX', 'DUMP TRUCK', 
    'DUKW353', 'DUET', 'DUCATO PANORAMA', 
    'DUCATI', 'DUALSPORT-200', 'DUAL SPORT-125', 
    'DTO', 'DT90', 'DT-150', 
    'DT125S-08', 'DT125S-07', 'DT125S-06', 
    'DT125S-05', 'DT-125S', 'DT 50', 
    'DT 175', 'DT', 'DT', 
    'DS1', 'DR-Z400EK6', 'DR-Z400EK2', 
    'DRZ250K2', 'DR-Z125LK3', 'DREAM NEO', 
    'DRAG STAR', 'DOMOTO', 'DOBLO', 
    'DOBLO', 'DOBLO', 'DOBLO', 
    'DM250', 'DM1731L', 'DM125TS', 
    'DM01', 'DIRT 250', 'DION', 
    'DIO-110', 'DIO Z4', 'DIO AF18', 
    'DIO 50', 'DIO 110', 'DIESEL', 
    'DIAVEL', 'DIAMANTE', 'DFM SPORT J150 19', 
    'DFM SPEED', 'DFM CLASSIC', 'DFA6750BG', 
    'DFA6720KB', 'DESERT X', 'DELTA', 
    'DELTA', 'DELTA', 'DEFENDER 200', 
    'DBX', 'DB-200', 'DAYTONA', 
    'DAWN125', 'DART', 'DAMAS SUPER DLX', 
    'DAMAS', 'DAMAS', 'DAMAS', 
    'DAMAS', 'DAMAS', 'DAKOTA', 
    'DAEWOO', 'D-600', 'D60', 
    'D-50', 'D-35', 'D3', 
    'D-250', 'D-22', 'D-150', 
    'D-100', 'CY80', 'CY', 
    'CX60', 'CUPRA', 'CUBE', 
    'CUB NEW', 'CUB 50', 'CUB', 
    'CTX 200', 'CT-100', 'CT 200H', 
    'CT - 100', 'CRZ H', 'CRYPTON 105', 
    'CRYPTON', 'CRX-250', 'CRX GT', 
    'CRX', 'CRV-X', 'CRUZE', 
    'CRUX-S', 'CRUX REV', 'CROX-150', 
    'CROX-150', 'CROX-125', 'CROSSTRAINER', 
    'CROSSTOUR', 'CROSSFIRE', 'CROSS FOX', 
    'CROSS FOX', 'CRFX', 'CRF450X', 
    'CRF230F', 'CRF1000A', 'CRF 250', 
    'CRF 1000', 'CRF', 'CR80R2', 
    'CR6', 'CR-5', 'CR250R2', 
    'CR1200', 'CQ80-3', 'CPI', 
    'COUPE TIBURON', 'COUPE TIBURON', 'COUNTY', 
    'COUNTRY', 'COSTA', 'COSMOS', 
    'COSMOS', 'COSMOS', 'CORVETTE', 
    'CORTADORA', 'CORSA', 'CORSA', 
    'CORSA', 'CORONET', 'CORDOBA', 
    'COOPER S ROADSTER', 'CONTY', 'CONSTELLATION', 
    'CONCOURS', 'COMPASS', 'COMO', 
    'COMIL', 'COLT', 'COLT', 
    'COLT', 'COBRA 200 RACING', 'COBALT', 
    'COASTER', 'COACH', 'CMV', 
    'CMTA', 'CMSP', 'CMP', 
    'CMB', 'CM6508L', 'CM400T', 
    'CM3', 'CLUB WAGON', 'CLUB WAGON', 
    'CLUB CAR PRECEDENT', 'CLUB CAR PIONNER', 
    'CLS 63 AMG', 'CLS 55', 'CLS 53 AMG', 
    'CLK55', 'CLK320', 'CLK 500', 
    'CLK 430', 'CLK 350', 'CLK 280', 
    'CLK 240', 'CLK 230', 'CLK', 
    'CLK', 'CLASSIC 180', 'CLA 45', 
    'CL550', 'CL-350', 'CL', 
    'CL', 'CL', 'CIVILIAN', 
    'CIVIC TYPE R', 'CIVIC COUPE', 'CITYCLASS', 
    'CITY', 'CITY', 'CHRYSLER SPORT', 
    'CHORUS BUS', 'CHOPER', 'CHEYENNE', 
    'CHEVY-VAN', 'CHEVY-500', 'CHEVY-500', 
    'CHEVY EXPRESS', 'CHEVETTE', 'CHEVETTE', 
    'CHEROKEE TRAILHAWK', 'CHARM 150 SR', 
    'CHARIOT', 'CHARGER', 'CHAPPY', 
    'CHALLENGER', 'CHALLENGER', 'CH6391-SD', 
    'CGR 125 STORM', 'CGL 125', 'CG200R', 
    'CG-200', 'CG200', 'CG200', 
    'CG200', 'CG200', 'CG200', 
    'CG200', 'CG-150R', 'CG-150'];

    const price = 
    restrictedMakes.includes(make.toUpperCase()) || restrictedModels.includes(model.toUpperCase()) 
      ? new Money(
          (insuranceAmount * 1 / parseFloat(CONVERSION_VALUE)) + 
          ((insuranceAmount * 1 / parseFloat(CONVERSION_VALUE) * 0.2)), 
          process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY
        ) 
      : new Money(
          (insuranceAmount * 1 / parseFloat(CONVERSION_VALUE)), 
          process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY
        );

  return price && price.amount
}
