import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { Form, FieldTextInput, Button } from '../../components';

import css from './PasswordRecoveryForm.module.css';

import suzuki from './Grupo -8.png';
import car from './005-car.png';
import pickup from './001-pick-up-truck.png';
import family from './002-family-car.png';
import limousine from './006-limousine.png';
import electric from './007-electric-car.png';

class PasswordRecoveryFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            // pristine,
            // initialValues,
            intl,
            inProgress,
            recoveryError,
            values,
          } = fieldRenderProps;

          // email
          // const emailLabel = intl.formatMessage({
          //   id: 'PasswordRecoveryForm.emailLabel',
          // });
          // const emailPlaceholder = intl.formatMessage({
          //   id: 'PasswordRecoveryForm.emailPlaceholder',
          // });
          const emailRequiredMessage = intl.formatMessage({
            id: 'PasswordRecoveryForm.emailRequired',
          });
          const emailNotFoundMessage = intl.formatMessage({
            id: 'PasswordRecoveryForm.emailNotFound',
          });
          const emailInvalidMessage = intl.formatMessage({
            id: 'PasswordRecoveryForm.emailInvalid',
          });

          const emailRequired = validators.required(emailRequiredMessage);
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // In case a given email is not found, pass a custom error message
          // to be rendered with the input component
          const customErrorText = isPasswordRecoveryEmailNotFoundError(recoveryError)
            ? emailNotFoundMessage
            : null;
          // const initialEmail = initialValues ? initialValues.email : null;
          const emailTouched = values.email !== this.submittedValues.email;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          // const submittedOnce = Object.keys(this.submittedValues).length > 0;
          // const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          // const submitDisabled =
          //   (pristine && !initialEmail) || submitInProgress || pristineSinceLastSubmit;

          // const loginLink = (
          //   <NamedLink name="LoginPage" className={css.modalHelperLink}>
          //     <FormattedMessage id="PasswordRecoveryForm.loginLinkText" />
          //   </NamedLink>
          // );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div>
                <div className={css.rowWrapper}>
                  <div className={css.mobile}>
                    <p className={css.mobileText}>Recuperar cuenta</p>
                  </div>
                  <div className={css.inputWrapper}>
                    <div className={css.box}>
                      <p className={css.title}>Recuperar contraseña</p>
                      <p className={css.subtitle}>
                        Escriba su correo electrónico, recibirá un código para reestablecer la cuenta.
                      </p>
                      <div className={css.column}>
                        <FieldTextInput
                          className={css.email}
                          type="email"
                          id={formId ? `${formId}.email` : 'email'}
                          name="email"
                          autoComplete="email"
                          placeholder="Correo eléctronico"
                          validate={validators.composeValidators(emailRequired, emailValid)}
                          customErrorText={emailTouched ? null : customErrorText}
                        />
                        <Button
                          className={css.button}
                          type="submit"
                          inProgress={submitInProgress}
                        //  disabled={submitDisabled}
                        >
                          Restablecer contraseña
                        </Button>
                      </div>
                    </div>
                    <div className={css.row}>
                      <div className={css.carBox}>
                        <img src={car} className={css.car} alt='coche' />
                      </div>
                      <div className={css.carBox}>
                        <img src={limousine} className={css.car} alt='limusina' />
                      </div>
                      <div className={css.carBox}>
                        <img src={family} className={css.car} alt='familia' />
                      </div>
                      <div className={css.carBox}>
                        <img src={electric} className={css.car} alt='eléctrico' />
                      </div>
                      <div className={css.carBox}>
                        <img src={pickup} className={css.car} alt='camioneta' />
                      </div>
                    </div>
                  </div>
                  <div className={css.formDetailsRight}>
                    <div className={css.box1}>
                      <h1 className={css.title1}>Renta tu vehículo favorito</h1>
                      <p className={css.pTag}>
                        <strong>Réntalo®</strong>
                        <span className={css.text1}>
                          {' '}
                          Es la plataforma de alquiler de autos más novedosa, segura y rentable. En Réntalo si eres dueño de un vehículo o una flota completa puedes rentarlo a ciudadanos y turistas de todo el mundo. El viaje estará asegurado por nuestra póliza de seguro para tranquilidad del propietario y el conductor.
                        </span>
                      </p>
                      <img src={suzuki} className={css.pic} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.modalHelperText}>
                    <FormattedMessage
                      id="PasswordRecoveryForm.loginLinkInfo"
                      values={{ loginLink }}
                    />
                  </span>
                </p> */}

              {/* </div> */}
            </Form>
          );
        }}
      />
    );
  }
}

PasswordRecoveryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  recoveryError: null,
};

const { bool, string } = PropTypes;

PasswordRecoveryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,

  inProgress: bool,
  recoveryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PasswordRecoveryForm = compose(injectIntl)(PasswordRecoveryFormComponent);
PasswordRecoveryForm.displayName = 'PasswordRecoveryForm';

export default PasswordRecoveryForm;
